import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../components/layout';
import LinkAsButton from '../ui-components/LinkAsButton';
import SEO from "../components/seo";

const IndexPage = ({ data }) => {
	const { edges } = data.allMdx;

	return (
		<Layout>
			<SEO title='Michel Erler'/>
			<div>
				<div>
					<h1 className='text-3xl'>Michel Erler</h1>
					<p className='my-4'>I'm a product designer interested in the decentralized web – currently working as Product Design Lead at <a href='https://parity.io' target="_blank" rel="noreferrer">Parity Technologies</a>.</p>
					<p className='my-4'>Other interests of mine span cities, longevity and liberalism in the 21st century.</p>
					<div className='flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-2'>
						<LinkAsButton
							href='mailto:mail@michelerler.com'
							priority={true}
							>
								Get in touch
						</LinkAsButton>
						<LinkAsButton
							href='https://warpcast.com/erler'
							priority={false}
						>
								Connect on Farcaster
						</LinkAsButton>
					</div>
				</div>
				<div className='mt-16'>
					<h2 className='text-2xl'>Input / Output</h2>
					{edges.map(edge => {
						const { frontmatter } = edge.node;
						return (
							<div className='mt-4' key={frontmatter.path}>
								<Link className='text-white font-semibold' to={frontmatter.path}>{frontmatter.title}</Link>
								&nbsp;
								<small className='text-gray-400'>
									{' '}
									posted {frontmatter.date}
								</small>
								<p>{frontmatter.excerpt}</p>
								<br />
							</div>
						);
					})}
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`
	query HomePageQuery {
		allMdx(sort: {order: DESC, fields: frontmatter___date}) {
			totalCount
			edges {
				node {
				  	frontmatter {
						date(fromNow: true)
						excerpt
						path
						tags
						title
					}
				}
			  }
		}
	}
`;

export default IndexPage;