import React from 'react';

const LinkAsButton = (({children, href, priority}) => {
    return (
        <a 
            className={`inline-flex text-sm text-center justify-center px-6 py-3 rounded-lg font-semibold transition-colors ${priority ? `bg-blue-800 shadow-btn-inner hover:bg-blue-600` : `bg-gray-800 hover:bg-gray-700`}`}
            href={href}
            target='_blank'
            rel='noopener noreferrer'
        >
            {children}
        </a>
    )
})

export default LinkAsButton;